// Node
import moment from 'moment';

import apiLoja from '../apis/loja';

import 'moment/locale/pt-br';

/**
 * Solicita reenvio de arquivos
 * @param {Integer || String} pedido
 * @param {Integer || String} pedido_item
 */
export const requestResendFiles = (pedido, pedido_item) => {
  try {
    const response = apiLoja.post('/rpc/confirmar-reenvio', {
      pedido,
      pedido_item,
    });

    return response;
  } catch (error) {
    return error.response;
  }
};

/**
 * Verifica erros
 */
export const verifyOrderErrors = () => {
  try {
    const response = apiLoja.get('/verificar-pedidos');

    return response;
  } catch (error) {
    return error.response;
  }
};

/**
 * Envia os dados de contestação
 * @param {Object} data
 */
export const sendContest = async data => {
  const formData = new FormData();

  data.arquivos.forEach(file => {
    formData.append('arquivos[]', file);
  });

  formData.append('pedido_item', data.pedido_item);
  formData.append('motivo', data.motivo);
  formData.append('nr_fone', data.nr_fone);
  formData.append('periodo', data.periodo);

  try {
    const response = await apiLoja.post('/pedido/contestar', formData);

    return response;
  } catch (error) {
    return error.response;
  }
};

/**
 * Cancelar Pedido
 * @param {Object} data
 */

export const cancelOrder = async data => {
  try {
    const response = await apiLoja.post('/pedido/cancelar', data);
    return response;
  } catch (error) {
    return error.response;
  }
};

/**
 * cancelar Pedido Item
 * @param {Object} data
 */

export const cancelItem = async data => {
  try {
    const response = await apiLoja.post('/pedido/cancelar-item', data);
    return response;
  } catch (error) {
    return error.response;
  }
};

/**
 * Autorizar Produção Item
 * @param {Object} data
 */

export const AuthProdItem = async data => {

  try {
    const response = await apiLoja.post('/rpc/autorizar-producao', data);

    return response;
  } catch (error) {
    return error.response;
  }
};



/**
 * Formar Calendar
 * @param {Date} data
 */

export const formatCalendar = data => {
  moment.locale('pt');
  moment.weekdays(true);
  const localeData = moment.localeData();

  const date = new Date(data);
  const day = moment(date, 'YYYY/MM/DD').format('D');
  const dayWeekValue = moment(date).day();
  const weekDaysList = localeData.weekdays();
  const months = moment(date).month();
  const monthsList = localeData.months();

  const ObjectCalendar = {
    day,
    dayWeek: weekDaysList[dayWeekValue],
    month: monthsList[months],
  };

  return ObjectCalendar;
};

//Repetir pedido
export const repeatOrder = async (pedido, e) => {
  e.stopPropagation();
  try {
    const response = await apiLoja.post('/carrinho/comprar-novamente', {
      pedido: pedido,
      token: localStorage.getItem("carrinho"),
      dados: localStorage.getItem("product")
      //usar_arquivos_pedido_anterior: false,
    }
    )
    localStorage.setItem("usar_arquivos_pedido_anterior", response.data.data.arquivos_nao_reutilizaveis ? !response.data.data.arquivos_nao_reutilizaveis : true)

    if (response.status === 200) {
      window.location.reload();
    }

    return response
  } catch (error) {
    return error.response;
  }
};
